// extracted by mini-css-extract-plugin
export var aurther = "Testimonials2-module--aurther--2b3fb";
export var circleTes = "Testimonials2-module--circleTes--906cc";
export var content = "Testimonials2-module--content--f0517";
export var des = "Testimonials2-module--des--c87eb";
export var iconContainer = "Testimonials2-module--iconContainer--fca6d";
export var iconContainerLeft = "Testimonials2-module--iconContainerLeft--7ba9a";
export var personBg = "Testimonials2-module--personBg--c0619";
export var portfolioArrowIcon = "Testimonials2-module--portfolioArrowIcon--927f6";
export var portfolioArrowIconCover = "Testimonials2-module--portfolioArrowIconCover--aece0";
export var portfolioArrowRightIconCover = "Testimonials2-module--portfolioArrowRightIconCover--8a1f3";
export var row = "Testimonials2-module--row--ff68c";
export var section = "Testimonials2-module--section--aca12";
export var tesBtn = "Testimonials2-module--tesBtn--9d181";
export var tesDots = "Testimonials2-module--tesDots--5d3ff";
export var tesRec = "Testimonials2-module--tesRec--d86b5";
export var testimonials = "Testimonials2-module--testimonials--84599";