// extracted by mini-css-extract-plugin
export var cardBg = "TechStacksIos-module--cardBg--e067c";
export var cards = "TechStacksIos-module--cards--143e5";
export var cardsContent = "TechStacksIos-module--cardsContent--208c0";
export var description = "TechStacksIos-module--description--4f136";
export var globalIndusIosTab = "TechStacksIos-module--globalIndusIosTab--0ed47";
export var heading = "TechStacksIos-module--heading--ea5aa";
export var iconssCard = "TechStacksIos-module--iconssCard--947e7";
export var nav = "TechStacksIos-module--nav--7c073";
export var navItem = "TechStacksIos-module--nav-item--cbd04";
export var navbarBlock = "TechStacksIos-module--navbarBlock--073bd";
export var tabData = "TechStacksIos-module--tabData--563d8";
export var tech = "TechStacksIos-module--tech--5951f";
export var techIcon = "TechStacksIos-module--techIcon--d0858";
export var techImg = "TechStacksIos-module--techImg--66354";
export var technologyIcon = "TechStacksIos-module--technologyIcon--c2aa6";