// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--cbca2";
export var clientDots2 = "Testimonials-module--clientDots2--2c69e";
export var content = "Testimonials-module--content--e0de3";
export var dots3 = "Testimonials-module--dots3--701f9";
export var hireArrow = "Testimonials-module--hireArrow--4d7da";
export var iconContainer = "Testimonials-module--iconContainer--462b6";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--e51d4";
export var imgBtn = "Testimonials-module--imgBtn--84147";
export var person = "Testimonials-module--person--5f7ef";
export var personBg = "Testimonials-module--personBg--16be4";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--1753a";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--e5b1d";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--47167";
export var row = "Testimonials-module--row--59a0c";
export var section = "Testimonials-module--section--174e5";
export var staffRec = "Testimonials-module--staffRec--3e28a";
export var testName = "Testimonials-module--testName--eebbd";
export var testimonials = "Testimonials-module--testimonials--f5cfa";
export var testimonialsContent = "Testimonials-module--testimonialsContent--ae6ac";
export var vector2 = "Testimonials-module--vector2--6e124";