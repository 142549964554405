// extracted by mini-css-extract-plugin
export var arrowHead = "Banner-module--arrowHead--f2878";
export var bannerHeading = "Banner-module--bannerHeading--b94cb";
export var des = "Banner-module--des--4ba1d";
export var form = "Banner-module--form--b95b3";
export var handDiv = "Banner-module--handDiv--593ce";
export var handImg = "Banner-module--handImg--7f880";
export var heading = "Banner-module--heading--17cf2";
export var mernBanner = "Banner-module--mernBanner--09f87";
export var storyIos = "Banner-module--storyIos--85e0e";
export var subtitle = "Banner-module--subtitle--af1fc";
export var success = "Banner-module--success--3c10a";