import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import hamza from "../../images/hireIOS/hamza.png"
import zeeshan from "../../images/hireIOS/zeeshan.png"
import ahmed from "../../images/hireIOS/ahmed.png"
import stars from "../../images/hire-saas/stars.svg"
import * as styles from "./TalentPool.module.scss"
import mernIcon from "../../images/hireMernStack/armyIcon.svg"
const TalentPool = ({ strapiData }) => {
  const devSDetails = [
    {
      name: "Hamza Sajid",
      experience: "5 years of experience",
      desc: "He develops scalable applications, with strong knowledge of concurrent programming, microservices architecture and performs code optimization for efficiency.",
      rating: stars,
      reviews: "<b>5.0</b> Stars Reviews",
    },
    {
      name: "Zeeshan Khan",
      experience: "8 years of experience",
      desc: "Hamza possesses expertise in developing robust and efficient software solutions. So hire him for your team to get high-performing and efficient applications.",
      rating: stars,
      reviews: "<b>5.0</b> Stars Reviews",
    },
    {
      name: "Ahmad Wahaj",
      experience: "6 years of experience",
      desc: "Nadeem can build scalable solutions with ability to design & maintain complex systems with track of high performance and delivery of production ready software.",
      rating: stars,
      reviews: "<b>5.0</b> Stars Reviews",
    },
  ]

  const dataDev1 = [
    {
      skill: "iOS",
    },
    {
      skill: "Swift",
    },
    {
      skill: "Node.JS",
    },
    {
      skill: "C",
    },
    {
      skill: "Python",
    },
    {
      skill: "Java",
    },
    {
      skill: "Bootstrap",
    },
    {
      skill: "PHP",
    },
    {
      btn: "See More",
    },
  ]
  const dataDev2 = [
    {
      skill: "iOS",
    },
    {
      skill: "Swift",
    },
    {
      skill: "Node.JS",
    },
    {
      skill: "C",
    },
    {
      skill: "Python",
    },
    {
      skill: "Java",
    },
    {
      skill: "Bootstrap",
    },
    {
      skill: "PHP",
    },
    {
      btn: "See More",
    },
  ]
  const dataDev3 = [
    {
      skill: "iOS",
    },
    {
      skill: "Swift",
    },
    {
      skill: "Node.JS",
    },
    {
      skill: "C",
    },
    {
      skill: "Python",
    },
    {
      skill: "Java",
    },
    {
      skill: "Bootstrap",
    },
    {
      skill: "PHP",
    },
    {
      btn: "See More",
    },
  ]
  return (
    <React.Fragment>
      <div className={styles.TalentPoolhireIOS}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={styles.cardWrapper}>
            {devSDetails &&
              devSDetails.map((e, i) => (
                <Col lg={4} md={6} sm={12} xs={12} key={i}>
                  <div
                    className={
                      i === 1
                        ? styles.card2
                        : i === 2
                        ? styles.card3
                        : styles.card
                    }
                  >
                    <div className={` d-flex ${styles.developersData}`}>
                      <div>
                        <img
                          src={
                            i === 0
                              ? hamza
                              : i === 1
                              ? zeeshan
                              : i === 2
                              ? ahmed
                              : ""
                          }
                          alt={e?.name}
                        />
                      </div>
                      <div className={styles.devContent}>
                        <h3>{e?.name}</h3>
                        <p className={styles.experience}>{e?.experience}</p>
                      </div>
                    </div>
                    <div className={styles.allSkills}>
                      {i === 0
                        ? dataDev1 &&
                          dataDev1?.map((s, e) => (
                            <div className={styles.skills} key={e}>
                              <div
                                className={
                                  e === 0
                                    ? styles.skill1
                                    : e === 1
                                    ? styles.skill2
                                    : e === 2
                                    ? styles.skill3
                                    : e === 3
                                    ? styles.skill4
                                    : e === 4
                                    ? styles.skill5
                                    : e === 5
                                    ? styles.skill6
                                    : e === 6
                                    ? styles.skill7
                                    : e === 7
                                    ? styles.skill8
                                    : ""
                                }
                              >
                                {s?.skill}
                              </div>
                            </div>
                          ))
                        : i === 1
                        ? dataDev2 &&
                          dataDev2.map((sl, el) => (
                            <div className={styles.skills} key={el}>
                              <div
                                className={
                                  el === 0
                                    ? styles.firstDev2
                                    : el === 1
                                    ? styles.secondDev2
                                    : el === 2
                                    ? styles.thirdDev2
                                    : el === 3
                                    ? styles.fourthDev2
                                    : el === 4
                                    ? styles.fifthDev2
                                    : el === 5
                                    ? styles.sixthDev2
                                    : el === 6
                                    ? styles.seventhDev2
                                    : el === 7
                                    ? styles.eightDev2
                                    : ""
                                }
                              >
                                {sl?.skill}
                              </div>
                            </div>
                          ))
                        : i === 2
                        ? dataDev3 &&
                          dataDev3.map((al, ei) => (
                            <div className={styles.skills} key={ei}>
                              <div
                                className={
                                  ei === 0
                                    ? styles.firstDev3
                                    : ei === 1
                                    ? styles.secondDev3
                                    : ei === 2
                                    ? styles.thirdDev3
                                    : ei === 3
                                    ? styles.fourthDev3
                                    : ei === 4
                                    ? styles.fifthDev3
                                    : ei === 5
                                    ? styles.sixthDev3
                                    : ei === 6
                                    ? styles.seventhDev3
                                    : ei === 7
                                    ? styles.eightDev3
                                    : ""
                                }
                              >
                                {al?.skill}
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                    <p className={styles.cardDesc}>{e?.desc}</p>
                    <img src={e?.rating} alt="rating" />
                    <div
                      className={styles.review}
                      dangerouslySetInnerHTML={{
                        __html: e?.reviews,
                      }}
                    />
                    <div className={styles.IosdevBtn}>
                      {/* {strapiData?.buttons[0] && (
                                                <Button  className={styles.btn}>
                                                    {strapiData?.buttons[0]?.title}
                                                </Button>
                                            )} */}
                      <Link to="#">
                        <Button className={styles.IosBtn}>
                          <img
                            src={mernIcon}
                            alt="mern icon"
                            className={styles.mernIcon}
                          />
                          {`Hire ${e?.name}`}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TalentPool
