import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./tabbing.scss"
import * as styles from "./TechStacksIos.module.scss"

const data = [
  {
    title: "Languages",
    tech: [
      {
        name: "Swift",
        icon: "https://invozone-backend.s3.amazonaws.com/swift_09fad37072.svg",
      },
      {
        name: "Objective-C",
        icon: "https://invozone-backend.s3.amazonaws.com/objective_c_5c432f35bd.svg",
      },
      {
        name: "iOS SDK",
        icon: "https://invozone-backend.s3.amazonaws.com/C_programming_language_c5f5554b08.svg",
      },
    ],
  },
  {
    title: "Frameworks",
    tech: [
      {
        name: "Push Notifications",
        icon: "https://invozone-backend.s3.amazonaws.com/push_notifications_ae901723ab.png",
      },
      {
        name: "3D Touch",
        icon: "https://invozone-backend.s3.amazonaws.com/3d_touch_3eea260aeb.svg",
      },
      {
        name: "SiriKit",
        icon: "https://invozone-backend.s3.amazonaws.com/sirikit_bf8f356854.svg",
      },
      {
        name: "HealthKit",
        icon: "https://invozone-backend.s3.amazonaws.com/healthkit_7cb06bfd7f.svg",
      },
      {
        name: "CallKit",
        icon: "https://invozone-backend.s3.amazonaws.com/callkit_420fdf1568.svg",
      },
      {
        name: "In-App Purchases",
        icon: "https://invozone-backend.s3.amazonaws.com/in_app_purchases_3e7f398541.svg",
      },
      {
        name: "Cocoa Pods",
        icon: "https://invozone-backend.s3.amazonaws.com/cocoa_pods_e85d36e771.svg",
      },
      {
        name: "AFNetworking",
        icon: "https://invozone-backend.s3.amazonaws.com/afnetworking_c53f8999e8.svg",
      },
      {
        name: "Core Data",
        icon: "https://invozone-backend.s3.amazonaws.com/core_data_b552024c63.svg",
      },
      {
        name: "Cocoa Touch",
        icon: "https://invozone-backend.s3.amazonaws.com/cocoa_touch_7a50110324.svg",
      },
    ],
  },
  {
    title: "Databases",
    tech: [
      {
        name: "SQLite",
        icon: "https://invozone-backend.s3.amazonaws.com/sqlite_ae7555c11f.svg",
      },
      {
        name: "Realm",
        icon: "https://invozone-backend.s3.amazonaws.com/realm_7020e1191d.svg",
      },
      {
        name: "CoreData",
        icon: "https://invozone-backend.s3.amazonaws.com/core_data_b552024c63.svg",
      },
    ],
  },
]

const TechStacksIos = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="IosTab">
      <div className={styles.globalIndusIosTab}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContent}>
              <div>
                <Nav variant="pills" className={`IosTab`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlock">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default TechStacksIos
