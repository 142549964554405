// extracted by mini-css-extract-plugin
export var SliderWidth = "TopChoice-module--SliderWidth--72039";
export var arrowImg = "TopChoice-module--arrowImg--88438";
export var card = "TopChoice-module--card--c66f8";
export var cardImg = "TopChoice-module--cardImg--aea7f";
export var cardMian = "TopChoice-module--cardMian--d3063";
export var cardWrapper = "TopChoice-module--cardWrapper--83ab3";
export var cards = "TopChoice-module--cards--7de14";
export var description = "TopChoice-module--description--a77d3";
export var heading = "TopChoice-module--heading--12590";
export var headingPremium = "TopChoice-module--headingPremium--6f50e";
export var mainWorkIOS = "TopChoice-module--mainWorkIOS--04f29";
export var portfolioArrowIcon = "TopChoice-module--portfolioArrowIcon--ea7ca";
export var portfolioArrowIconCover = "TopChoice-module--portfolioArrowIconCover--072be";
export var portfolioArrowRightIconCover = "TopChoice-module--portfolioArrowRightIconCover--b5871";
export var premiumImg = "TopChoice-module--premiumImg--8b4c3";
export var providingImg = "TopChoice-module--providingImg--837dc";
export var reactJsIconContainer = "TopChoice-module--reactJsIconContainer--75734";
export var reactJsIconContainerLeft = "TopChoice-module--reactJsIconContainerLeft--13a18";
export var teamButton = "TopChoice-module--teamButton--f91b9";
export var trailBg = "TopChoice-module--trailBg--64fb1";