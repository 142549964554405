// extracted by mini-css-extract-plugin
export var IosBtn = "TalentPool-module--IosBtn--2fbe1";
export var IosdevBtn = "TalentPool-module--IosdevBtn--c60d5";
export var TalentPoolhireIOS = "TalentPool-module--TalentPoolhireIOS--e6014";
export var allSkills = "TalentPool-module--allSkills--07900";
export var card = "TalentPool-module--card--14f27";
export var card2 = "TalentPool-module--card2--1c46b";
export var card3 = "TalentPool-module--card3--5f206";
export var cardDesc = "TalentPool-module--cardDesc--48169";
export var cardWrapper = "TalentPool-module--cardWrapper--e4e73";
export var description = "TalentPool-module--description--e07dc";
export var devContent = "TalentPool-module--devContent--9ae1c";
export var developersData = "TalentPool-module--developersData--3e8ff";
export var eightDev2 = "TalentPool-module--eightDev2--45908";
export var eightDev3 = "TalentPool-module--eightDev3--fb667";
export var experience = "TalentPool-module--experience--b6c2f";
export var expert = "TalentPool-module--expert--a37a7";
export var fifthDev2 = "TalentPool-module--fifthDev2--d7f58";
export var fifthDev3 = "TalentPool-module--fifthDev3--6ac8e";
export var firstDev2 = "TalentPool-module--firstDev2--76678";
export var firstDev3 = "TalentPool-module--firstDev3--09e14";
export var fourthDev2 = "TalentPool-module--fourthDev2--77bd9";
export var fourthDev3 = "TalentPool-module--fourthDev3--21510";
export var heading = "TalentPool-module--heading--e0a33";
export var mernIcon = "TalentPool-module--mernIcon--9d2cc";
export var review = "TalentPool-module--review--e9fb1";
export var secondDev2 = "TalentPool-module--secondDev2--0bf87";
export var secondDev3 = "TalentPool-module--secondDev3--5f089";
export var seventhDev2 = "TalentPool-module--seventhDev2--754aa";
export var seventhDev3 = "TalentPool-module--seventhDev3--a3943";
export var sixthDev2 = "TalentPool-module--sixthDev2--19309";
export var sixthDev3 = "TalentPool-module--sixthDev3--cee30";
export var skill1 = "TalentPool-module--skill1--9295c";
export var skill2 = "TalentPool-module--skill2--ae359";
export var skill3 = "TalentPool-module--skill3--ce1bc";
export var skill4 = "TalentPool-module--skill4--040b0";
export var skill5 = "TalentPool-module--skill5--f5739";
export var skill6 = "TalentPool-module--skill6--714f0";
export var skill7 = "TalentPool-module--skill7--819c3";
export var skill8 = "TalentPool-module--skill8--0891d";
export var skills = "TalentPool-module--skills--7c0fb";
export var thirdDev2 = "TalentPool-module--thirdDev2--d2962";
export var thirdDev3 = "TalentPool-module--thirdDev3--8b15b";