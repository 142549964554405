// extracted by mini-css-extract-plugin
export var attachment = "HireIosForm-module--attachment--d87e6";
export var attachmentLabel = "HireIosForm-module--attachmentLabel--826b7";
export var errorTxt = "HireIosForm-module--errorTxt--d223d";
export var fieldColor = "HireIosForm-module--fieldColor--5f883";
export var formControl = "HireIosForm-module--form-control--9aae3";
export var formInner = "HireIosForm-module--formInner--08976";
export var hireFormIos = "HireIosForm-module--hireFormIos--17a7f";
export var hr = "HireIosForm-module--hr--cf663";
export var newlinks = "HireIosForm-module--newlinks--0c701";
export var query = "HireIosForm-module--query--9bba9";
export var salesFormCheckBoxSize = "HireIosForm-module--salesFormCheckBoxSize--1f119";
export var salesFormRowHeight = "HireIosForm-module--salesFormRowHeight--c53c4";