import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import * as styles from "./HowHireDev.module.scss"

const HowHireDev = ({ strapiData }) => {
  return (
    <div className={styles.NextGenHowHireIosDev}>
      <Container>
        <div className={styles.heading}>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
        <Row className="gap-30">
          {strapiData?.cards?.map((e, i) => (
            <Col lg={3} md={6} sm={12} xs={12}>
              <div className={styles.cards}>
                <p>Step 0{i + 1}</p>
                <h3>{e?.title}</h3>
                <lottie-player
                  autoplay
                  loop
                  src={e?.image1[0]?.localFile?.publicURL}
                  style={{ height: "100px", margin: "0px" }}
                />
              </div>
            </Col>
          ))}
        </Row>
        <div className={`${styles.bannerBtn}`}>
          {strapiData?.buttons[0] && (
            <Link to={strapiData?.buttons[0]?.url} className={styles.cvButtn}>
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}

export default HowHireDev
