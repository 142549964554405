// extracted by mini-css-extract-plugin
export var btn = "Discover-module--btn--63d4b";
export var cards = "Discover-module--cards--8b195";
export var description = "Discover-module--description--7f840";
export var hireCyberDiscover = "Discover-module--hireCyberDiscover--808e1";
export var hireDiscover = "Discover-module--hireDiscover--897aa";
export var hireDiscoverDesktop = "Discover-module--hireDiscoverDesktop--74781";
export var hireDiscoverMobile = "Discover-module--hireDiscoverMobile--a9d37";
export var hireImg = "Discover-module--hireImg--72b4d";
export var subTitle = "Discover-module--subTitle--e83f0";
export var talent = "Discover-module--talent--b8533";
export var teamBtn = "Discover-module--teamBtn--235a0";
export var text = "Discover-module--text--c85e0";
export var tick = "Discover-module--tick--34fae";