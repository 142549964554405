import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import "./TechMobile.scss"
import * as styles from "./TechStacksMobileIos.module.scss"

const TechStacksIosMobile = ({ strapiData }) => {
  const data = [
    {
      title: "Languages",
      tech: [
        {
          name: "Swift",
          icon: "https://invozone-backend.s3.amazonaws.com/swift_09fad37072.svg",
        },
        {
          name: "Objective-C",
          icon: "https://invozone-backend.s3.amazonaws.com/objective_c_5c432f35bd.svg",
        },
        {
          name: "iOS SDK",
          icon: "https://invozone-backend.s3.amazonaws.com/C_programming_language_c5f5554b08.svg",
        },
      ],
    },
    {
      title: "Frameworks",
      tech: [
        {
          name: "Push Notifications",
          icon: "https://invozone-backend.s3.amazonaws.com/push_notifications_ae901723ab.png",
        },
        {
          name: "3D Touch",
          icon: "https://invozone-backend.s3.amazonaws.com/3d_touch_3eea260aeb.svg",
        },
        {
          name: "SiriKit",
          icon: "https://invozone-backend.s3.amazonaws.com/sirikit_bf8f356854.svg",
        },
        {
          name: "HealthKit",
          icon: "https://invozone-backend.s3.amazonaws.com/healthkit_7cb06bfd7f.svg",
        },
        {
          name: "CallKit",
          icon: "https://invozone-backend.s3.amazonaws.com/callkit_420fdf1568.svg",
        },
        {
          name: "In-App Purchases",
          icon: "https://invozone-backend.s3.amazonaws.com/in_app_purchases_3e7f398541.svg",
        },
        {
          name: "Cocoa Pods",
          icon: "https://invozone-backend.s3.amazonaws.com/cocoa_pods_e85d36e771.svg",
        },
        {
          name: "AFNetworking",
          icon: "https://invozone-backend.s3.amazonaws.com/afnetworking_c53f8999e8.svg",
        },
        {
          name: "Core Data",
          icon: "https://invozone-backend.s3.amazonaws.com/core_data_b552024c63.svg",
        },
        {
          name: "Cocoa Touch",
          icon: "https://invozone-backend.s3.amazonaws.com/cocoa_touch_7a50110324.svg",
        },
      ],
    },
    {
      title: "Databases",
      tech: [
        {
          name: "SQLite",
          icon: "https://invozone-backend.s3.amazonaws.com/sqlite_ae7555c11f.svg",
        },
        {
          name: "Realm",
          icon: "https://invozone-backend.s3.amazonaws.com/realm_7020e1191d.svg",
        },
        {
          name: "CoreData",
          icon: "https://invozone-backend.s3.amazonaws.com/core_data_b552024c63.svg",
        },
      ],
    },
  ]

  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  return (
    <div className="techIosMobile">
      <div className={styles.globalIndusIosTech}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`IosAccord ${
                      e?.title === activeAccordion ? "active-accordion" : ""
                    } ${
                      (i === 1 || i === 3) && e?.title !== activeAccordion
                        ? "second-accordion"
                        : (i === 0 || i === 2) && e?.title !== activeAccordion
                        ? "first-accordion"
                        : ""
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      i === 1 && e?.title === activeAccordion
                        ? { marginTop: "24px" }
                        : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`cardBody`}>
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default TechStacksIosMobile
