import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Row from "react-bootstrap/Row"
import tick from "../../images/hire-saas/tick.svg"
import expert from "../../images/hire-saas/hire-expert-saas-developer.webp"
import * as styles from "./Discover.module.scss"

const LatestFeatures = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.hireCyberDiscover}>
      <Container>
        <Row className="gap-30 align-items-center">
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
          >
            <div className={styles.hireDiscover}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <div className={styles.hireDiscoverMobile}>
                {image ? (
                  <GatsbyImage
                    placeholder="blurred"
                    decoding="async"
                    loading="lazy"
                    image={image}
                    alt={strapiData?.title}
                  />
                ) : (
                  <img
                    alt="icon"
                    src={expert}
                    decoding="async"
                    loading="lazy"
                  />
                )}
              </div>
            </div>
            <div className={styles.cards}>
              {strapiData?.cards &&
                strapiData?.cards?.map((item, index) => (
                  <div className={styles.talent} key={index}>
                    <img className={styles.tick} src={tick} alt={item?.title} />
                    <div className={styles.text}>
                      <p>{item?.title}</p>
                    </div>
                  </div>
                ))}
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className={styles.hireDiscoverDesktop}>
              {image ? (
                <GatsbyImage
                  placeholder="blurred"
                  decoding="async"
                  loading="lazy"
                  image={image}
                  alt={strapiData?.title}
                />
              ) : (
                <img alt="icon" src={expert} decoding="async" loading="lazy" />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LatestFeatures
