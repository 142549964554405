import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import HireIosForm from "../../components/hire-IOS/HireIosForm"
import tick from "../../images/hireMernStack/tick.png"
import * as styles from "./Banner.module.scss"

const cardData = [
  {
    img: tick,
    dec: "97% Customer Success Rate",
  },
  {
    img: tick,
    dec: "10+ Years Of Experience",
  },
  {
    img: tick,
    dec: "Free Consultation",
  },
  {
    img: tick,
    dec: "300+ Projects",
  },
]

const Banner = ({ strapiData, pageName }) => {
  return (
    <div id="iosbanner" className={styles.mernBanner}>
      <Container>
        <Row className="align-items-center">
          <Col xl={6} md={12} lg={6}>
            <div className={styles.heading}>
              <h1
                className={styles.bannerHeading}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p className={styles.bannerHeading}>Within 48 Hours</p>
              <p
                className={styles.subtitle}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <p
                className={styles.des}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
            <div className={`${styles.storyIos} d-flex`}>
              {cardData?.map((el, i) => (
                <div className={styles.success} key={i}>
                  <img src={tick} alt="tick" />
                  <p>{el?.dec}</p>
                </div>
              ))}
            </div>
          </Col>
          <Col xl={6} md={12} lg={6} id="mernForm" className={styles.form}>
            <HireIosForm pageName={pageName} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
