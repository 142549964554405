// extracted by mini-css-extract-plugin
export var cardBg = "TechStacksMobileIos-module--cardBg--9b302";
export var cards = "TechStacksMobileIos-module--cards--34e71";
export var cardsContent = "TechStacksMobileIos-module--cardsContent--04c08";
export var description = "TechStacksMobileIos-module--description--05686";
export var globalIndusIosTech = "TechStacksMobileIos-module--globalIndusIosTech--14f8f";
export var heading = "TechStacksMobileIos-module--heading--7985d";
export var iconssCard = "TechStacksMobileIos-module--iconssCard--b8c22";
export var nav = "TechStacksMobileIos-module--nav--a1900";
export var navItem = "TechStacksMobileIos-module--nav-item--76738";
export var navbarBlock = "TechStacksMobileIos-module--navbarBlock--d5751";
export var tabData = "TechStacksMobileIos-module--tabData--a6c83";
export var tech = "TechStacksMobileIos-module--tech--e344f";
export var techIcon = "TechStacksMobileIos-module--techIcon--25be1";
export var techImg = "TechStacksMobileIos-module--techImg--8acec";
export var technologyIcon = "TechStacksMobileIos-module--technologyIcon--6aacd";